 .input-file-component {
    padding-right: 15px;
     width: 120px;
     height: 120px;
     padding-bottom: 12px;
     label {
         width: 100% !important;
         height: 100% !important;
         border: 0;
         border-radius: 12px !important;;
         text-align: center;
         display: block;
         vertical-align: middle;
         line-height: 52px;
         font-size: 40px;
         font-weight: 100;
         color: #2F3947;
         cursor: pointer;
         position: relative;
        //  margin: 0 15px 0 auto;
         background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2396B5CEFF' stroke-width='1' stroke-dasharray='11' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e") !important;;

         &:hover {
             background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%232FB3D1FF' stroke-width='1' stroke-dasharray='11' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e") !important;;
         }
     }

     input[type="file"] {
         display: none;
     }

     &.preview {
         width: 100% !important;
         height: 100%;
         border: 1px solid #CEE1EA;
         border-radius: 10px;

         img {
             width: 100%;
         }
     }
 }