.main {
    height: auto;
    border-radius: 20px;
    position: relative;
    margin-top: 20px;
    padding: 0px 20px;
    @media screen and (max-width: 690px) {
        padding: 0px 10px;
    }
    &__container {
        padding: 20px 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;
        > div:not([class]) {
            display: block;
            width: calc(30% - 40px);
            position: relative;
            margin-right: 40px;
            // confirmar se fica bem com os cartões
            @media screen and (max-width: 1024px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 50px;
            }
            &:last-child {
                width: 70%;
                margin-right: 0;
                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
            }
            .back {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: #2F3947;
                margin-bottom: 40px;
                cursor: pointer;
                &::before {
                    content: "";
                    width: 60px;
                    height: 5px;
                    display: inline-block;
                    background-image: url("./../../../public/assets/arrow-back.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-right: 15px;
                }
            }
            h2 {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #2F3947;
                margin: 0 0 10px 0;
            }
            h4 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
                color: #809FB8;
                margin: 0 0 10px 0;
            }
            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-size: 14px;
                line-height: 22px;
                color: #2F3947;
                margin: 40px 0 0 0;
            }
        }
    }
    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #2F3947;
    }

}