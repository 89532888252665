.modal-confirmation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(36,55,70,.6);
    z-index: 99;
    &__container {
        position: relative;
        width: 50%;
        margin: 0 auto;
        height: fit-content;
        border-radius: 20px;
        background-color: #FFF;
        box-shadow: 0px 10px 20px 0px rgba(37, 63, 100, 0.25);
        top: 50%;
        transform: translateY(-50%);
        @media screen and (max-width: 1220px) {
            width: 65%;
        }
        @media screen and (max-width: 790px) {
            width: 95%;
        }
        .close {
            background-image: url("./../../../public/assets/close.svg");
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: 95% center;
            width: 17px !important;
            height: 15px;
            position: absolute;
            right: 30px;
            top: 30px;
            cursor: pointer;
            z-index: 999;
        }
    }
    &__body {
        display: block;
        width: 100%;
        position: relative;
        padding: 40px;
        @media screen and (max-width: 790px) {
            padding: 20px;
        }
        h3 {
            margin: 0 0 20px 0;
            color: #2F3947;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            @media screen and (max-width: 790px) {
                font-size: 12px;
            }
            span {
                color: #809FB8;
                @media screen and (max-width: 790px) {
                    display: block;
                }
            }
        }
        h2 {
            margin: 0 0 20px 0;
            color: #2F3947;
            font-family: "Montserrat";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            @media screen and (max-width: 790px) {
                font-size: 16px;
            }
        }
        > div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            position: relative;
            padding: 20px 0 0 0;
            > div {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                position: relative;
                width: 100%;
                margin: 0 0 20px 0;
                @media screen and (max-width: 790px) {
                    flex-direction: column;
                }
                > div {
                    width: 65%;
                    @media screen and (max-width: 790px) {
                        width: 100%;
                    }
                    &:first-child {
                        width: calc(35% - 20px);
                        margin-right: 20px;
                        @media screen and (max-width: 790px) {
                            width: 100%;
                            margin-right: 0;
                            margin-bottom: 10px;
                        }
                        > span {
                            color: #687484;
                            font-family: "Open Sans";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            display: block;
                        }
                    }
                    &.img {
                        display: flex;
                        flex-direction: column;
                        > div {
                            display: flex;
                            flex-direction: row;
                            margin: 0 0 20px 0;
                            justify-content: flex-start;
                            align-items: center;
                            position: relative;
                            img {
                                width: 74px;
                                height: 68px;
                                object-fit: cover;
                            }
                            span {
                                color: #2F3947;
                                font-family: "Montserrat";
                                font-size: 10px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 12px;
                                border-radius: 20px;
                                background: #FFF;
                                position: absolute;
                                left: 45px;
                                top: 45px;
                                padding: 1px 4px;
                            }
                            p {
                                margin: 10px;
                                color: #2F3947;
                                font-family: "Open Sans";
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 22px;
                            }
                        }
                    }
                    label {
                        color: #2F3947;
                        font-family: "Open Sans";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        @media screen and (max-width: 790px) {
                            font-size: 12px;
                        }
                    }
                    p {
                        color: #2F3947;
                        font-family: "Open Sans";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                        margin: 0;
                        @media screen and (max-width: 790px) {
                            font-size: 12px;
                        }
                    }
                }

            }
        }
    }
    &__btn {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border-top: 1px solid #D5EBF8;
        padding: 20px;
        button {
            cursor: pointer;
            &.cancel {
                color: #2F3947;
                font-family: "Montserrat";
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                border-radius: 10px;
                border: 1px solid #99B2C6;
                padding: 12px 40px;
                margin-right: 20px;
                background-color: #fff;
            }
            &.confirm {
                color: #FFF;
                font-family: "Montserrat";
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                border-radius: 10px;
                background: #1AD598;
                padding: 12px 40px;
                border: none;
            }
        }
    }
}