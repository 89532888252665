.loader {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99999999;
    //animation: back-change 5s infinite;
    background-color: black ;
    transition: background-color 0.2s ease;
    &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    &__item {
        width: 300px;
        height: 300px;
        background-image: url("./../../../public/assets/loader/logo1.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: background-image 0.2s ease, background-size 0.2s ease;
        //animation: loader-animation 5s steps(8) infinite;
    }
}


@keyframes loader-animation {
        0% { background-image: url("./../../../public/assets/loader/logo1.svg"); background-size: cover;}
        5% { background-image: url("./../../../public/assets/loader/logo2.svg"); background-size: cover;}
        10% { background-image: url("./../../../public/assets/loader/logo3.svg"); background-size: cover;}
        15% { background-image: url("./../../../public/assets/loader/logo4.svg"); background-size: cover;}
        20% { background-image: url("./../../../public/assets/loader/logo5.svg"); background-size: cover;}
        25% { background-image: url("./../../../public/assets/loader/logo6.svg"); background-size: cover;}
        30% { background-image: url("./../../../public/assets/loader/logo7.svg"); background-size: cover;}
        35% { background-image: url("./../../../public/assets/loader/logo8.svg"); background-size: cover;}
        40% { background-image: url("./../../../public/assets/loader/logo9.svg"); background-size: cover;}
        45% { background-image: url("./../../../public/assets/loader/logo10.svg"); background-size: cover;}
        55% { background-image: url("./../../../public/assets/loader/logo11.svg"); background-size: cover;}
        60% { background-image: url("./../../../public/assets/loader/logo12.svg"); background-size: cover;}
        65% { background-image: url("./../../../public/assets/loader/logo13.svg"); background-size: cover;}
        70% { background-image: url("./../../../public/assets/loader/logo14.svg"); background-size: cover;}
        75% { background-image: url("./../../../public/assets/loader/logo15.svg"); background-size: cover;}
        80% { background-image: url("./../../../public/assets/loader/logo16.svg"); background-size: cover;}
        85% { background-image: url("./../../../public/assets/loader/logo17.svg"); background-size: cover;}
        90% { background-image: url("./../../../public/assets/loader/logo18.svg"); background-size: 100%;}
        95% { background-image: url("./../../../public/assets/loader/logo20.svg"); background-size: 150%;}
        100% { 
            background-image: url("./../../../public/assets/loader/logo20.svg");
            background-size: 200%;
        } 
    }

  @keyframes back-change {
    0% {
        //background-color: #2F3947;
        background-color: black;
    }
    90% {
        background-color: black 
    }
    95% {
        background-color: white;
    }
    100% {
        background-color: white;
    }
  }