
.card {
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid #FFFFFF;
    border-radius: 30px;
    width: calc(20% - 20px);
    height: -moz-fit-content;
    height: initial;
    padding: 20px;
    margin: 0 20px 20px 0;
    max-width: 335px;
    min-width: 180px;
    @media screen and (max-width: 1640px) {
        width: calc(25% - 20px);
    }
    @media screen and (max-width: 1360px) {
        width: calc(33.33% - 20px);
    }
    @media screen and (max-width: 690px) {
        width: calc(42% - 20px);
    }
    @media screen and (max-width: 491px) {
        width:100%;
        max-width: none;
        min-width: unset;
    }
    &:hover {
        .card__body {
            figure {
                display: none;
                visibility: hidden;
                transition: all .1s ease;
            }
            .btn {
                opacity: 1;
                transition: all .1s ease;
                visibility: visible;
                //height: 87px;
                button {
                    opacity: 1;
                    transition: all .5s ease;
                    left: 0;
                    &:last-child {
                        left: 54%;
                    }
                }
            }
        }
        @media screen and (max-width: 960px) {
            .card__header {
                display: none;
                visibility: hidden;
            }
            .card__body {
                height: calc(185px + 70px);
                justify-content: flex-start;
                .btn {
                    height: 100%;
                    button {
                        height: 45%;
                        margin-bottom: 15px;
                    }
                }
                .counter {
                    display: none;
                }
            }
            
        }
    }
    &__container {
        background: #FFFFFF;
        border-radius: 20px;
        padding: 20px;
        position: relative;
        overflow: hidden;
        z-index: 0;
        display: flex;
        height: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-end;
        align-content: space-between;
        @media screen and (max-width: 1080px) {
            padding: 20px 15px;
        }
        &:hover {
            box-shadow: 0px 15px 25px rgba(37, 63, 100, 0.1);
        }
    }
    &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        z-index: 3;
        &.hovered {
            p, h4 {
                color: white;
            }
        }
        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #809FB8;
            margin: 0 0 10px 0;
        }
        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #2F3947;
            margin: 0;
            @media screen and (max-width: 790px) {
                font-size: 14px;
            }
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 156px;
        position: relative;
        width: 97%;
        @media screen and (max-width: 960px) {
            flex-direction: row;
            align-items: flex-end;
            height: 185px;
            width: 100%;
        }
        figure {
            display: block;
            visibility: visible;
            transition: all .5s ease;
            margin: 13px 0 0 0;
            height: 87px;
            @media screen and (max-width: 960px) {
                height: auto;
                position: absolute;
                left: 0;
            }
            img {
                @media screen and (max-width: 960px) {
                    width: 50px;
                }
            }
        }
        .counter {
            background: #E7EDF5;
            opacity: 0.6;
            border-radius: 20px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #2FB3D1;
            padding: 5px 10px;
            position: absolute;
            bottom: -9px;
            right: 2px;
            @media screen and (max-width: 960px) {
                bottom: 0px;
                right: -8px;
            }
        }
        .btn {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            position: relative;
            height: 0;
            opacity: 0;
            width: 100%;
            visibility: hidden;
            @media screen and (max-width: 960px) {
                flex-direction: column;
                justify-content: flex-start;
                height: fit-content;
            }
            button {
                background-color: #F2F4FA;
                box-shadow: 0px 3px 0px rgba(47, 57, 71, 0.1);
                border-radius: 20px;
                font-style: normal;
                font-size: 14px;
                line-height: 17px;
                color: #2F3947;
                border: none;
                padding: 30px 20px 20px 20px;
                width: 50%;
                height: 87px;
                text-align: left;
                cursor: pointer;
                font-family: 'Montserrat', sans-serif;
                opacity: 0;
                transition: all .5s ease;
                left: -25%;
                position: absolute;
                @media screen and (max-width: 1080px) {
                    padding: 25px 15px 15px 15px;
                    font-size: 12px;
                }
                @media screen and (max-width: 960px) {
                    display: block;
                    width: 100%;
                    position: relative;
                    height: 87px;
                    left: 0 !important;
                    margin-bottom: 5px;
                }
                &:hover {
                    background-color: #62ACA7;
                    color: white;
                    &::before {
                        width: 36em;
                        height: 36em;
                        @media screen and (max-width: 960px) {
                            height: 43em;
                        }
                    }
                }
                &::before {
                    content:"";
                    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
                    transition-duration: 500ms;
                    background-color: #62ACA7;
                    border-radius: 9999px;
                    width: 0;
                    height: 0;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);  
                    z-index: -1; 

                }
                &:last-child {
                    left: 80%;
                    &::before {
                        background-color: #2FB3D1;
                    }
                    &:hover {
                        
                        background-color: #2FB3D1;
                    }
                }
            }
        }
    }
}


.MuiTooltip-tooltip { 
    border-radius: 30px !important;
    box-shadow: -3px -2px 2px 1px rgba(0, 0, 0, 0.2);
}