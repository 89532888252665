.form {
    display: block;
    width: 100%;
    position: relative;
    &__container {
        background: #FFFFFF;
        border-radius: 20px;
        position: relative;
        form {
            padding: 40px;
            > div {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                margin-bottom: 40px;
                position: relative;
                flex-wrap: wrap;
                @media screen and (max-width: 790px) {
                    flex-direction: column;
                    margin-bottom: 20px;
                }
                &:last-child {
                    margin-bottom: 0;
                    justify-content: flex-end;
                    @media screen and (max-width: 790px) {
                        align-items: center;
                    }
                }
                > div {
                    margin-bottom: 40px;
                    display: flex !important;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    width: 50%;
                    margin-right: 0;
                    position: relative;
                    @media screen and (max-width: 790px) {
                        flex-direction: column;
                        width: 100% !important;
                        align-items: flex-start;
                        margin-bottom: 20px;
                    }
                    &:last-child {
                        // justify-content: flex-end;
                        // width: 100% !important;
                        @media screen and (max-width: 790px) {
                            justify-content: flex-start;
                        }
                    }
                    label {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 19px;
                        color: #2F3947;
                        width: 40%;
                        @media screen and (max-width: 790px) {
                            width: 100%;
                        }
                        span {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            color: #809FB8;
                            display: block;
                        }
                    }
                    input {
                        padding: 13px 15px 15px;
                        border: 1px solid #D3DFF3;
                        border-radius: 12px;
                        width: 55%;
                        @media screen and (max-width: 790px) {
                            width: 100%;
                        }
                        &:focus-visible {
                            border: 1px solid #62ACA7;
                            outline: #62ACA7;
                        }
                        &:valid {
                            border: 1px solid #62ACA7;
                            outline: #62ACA7;
                            box-shadow: 0px 0px 0px 3px rgba(98, 172, 167, 0.2);
                        }
                        &:invalid {
                            border: 1px solid #EA3A3D;
                        }
                    }
                    select {
                        width: 55%;
                        padding: 13px 15px 15px;
                        border: 1px solid #D3DFF3;
                        border-radius: 12px;
                        background-image: url("./../../../public/assets/arrow-down-select.svg");
                        background-repeat: no-repeat;
                        background-size: 12px;
                        background-position: 95% center;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        font-family: "Open Sans";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #2F3947;
                        @media screen and (max-width: 790px) {
                            width: 100%;
                        }
                        &:focus-visible {
                            border: 1px solid #62ACA7;
                            outline: #62ACA7;
                        }
                        &:valid {
                            border: 1px solid #62ACA7;
                            outline: #62ACA7;
                            box-shadow: 0px 0px 0px 3px rgba(98, 172, 167, 0.2);
                        }
                        &:invalid {
                            border: 1px solid #EA3A3D;
                        }
                    }
                    .select {
                        width: 55%;
                        padding: 13px 15px 15px;
                        border: 1px solid #D3DFF3;
                        border-radius: 12px;
                        position: relative;
                        cursor: pointer;
                        &::after {
                            content: "";
                            background-image: url("./../../../public/assets/arrow-down-select.svg");
                            background-repeat: no-repeat;
                            background-size: 12px;
                            background-position: 95% center;
                            display: inline-block;
                            width: 12px;
                            height: 6px;
                            position: absolute;
                            right: 10px;
                            top: 44%;
                            transform: rotate(0deg);
                            transition: transform .3s linear;
                        }
                        @media screen and (max-width: 790px) {
                            width: 100%;
                        }
                        &.active {
                            box-shadow: 0px 10px 20px rgba(37, 63, 100, 0.25);
                            &::after {
                                background-image: url("./../../../public/assets/arrow-up-select.svg");
                                transform: rotate(180deg);
                            }
                            ul {
                                display: block;
                            }
                        }
                        &.selected {
                            border: 1px solid #62ACA7;
                            outline: #62ACA7;
                        }
                        .selected-item {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            color: #2F3947;
                            background-repeat: no-repeat;
                            background-size: auto;
                            background-position: 0 center;
                            height: 16px;
                            &[data-value="item_2"] { // POSINT
                                background-image: url("./../../../public/assets/aplicacion/posint.svg");
                            }
                            &[data-value="item_10"] {  // POSINT WEB
                                background-image: url("./../../../public/assets/aplicacion/pweb.svg");
                            }
                            &[data-value="item_11"] { // Business
                                background-image: url("./../../../public/assets/aplicacion/bi.svg");
                            }
                            &[data-value="item_9"] { // Facturacion Electronica
                                background-image: url("./../../../public/assets/aplicacion/fe.svg");
                            }
                            &[data-value="item_1"] {
                                // Busint
                                background-image: url("./../../../public/assets/aplicacion/busint.svg");
                            }
                            &[data-value="item_12"] {
                                // Admin Posint
                                background-image: url("./../../../public/assets/aplicacion/admin.svg");
                            }

                        }
                        &.error {
                            border: 1px solid #EA3A3D;
                        }
                        ul {
                            display: none;
                            padding: 0;
                            margin: 0;
                            list-style: none;
                            position: absolute;
                            box-shadow: 0px 10px 20px rgba(37, 63, 100, 0.25);
                            background-color: white;
                            border-radius: 12px;
                            top: 55px;
                            left: 0;
                            width: 100%;
                            z-index: 1;
                            li {
                                padding: 10px;
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 10px;
                                line-height: 14px;
                                color: #384857;
                                text-align: right;
                                border-bottom: 1px solid #F5F4F4;
                                background-repeat: no-repeat;
                                background-size: auto;
                                background-position: 5% center;
                                &:last-child {
                                    border-bottom: none;
                                }
                                &[data-value="item_2"] {
                                    // POSINT
                                    background-image: url("./../../../public/assets/aplicacion/posint.svg");
                                } 
                                &[data-value="item_10"] {
                                    // POSINT WEB
                                    background-image: url("./../../../public/assets/aplicacion/pweb.svg");
                                } 
                                &[data-value="item_11"] {
                                    // Business
                                    background-image: url("./../../../public/assets/aplicacion/bi.svg");
                                } 
                                &[data-value="item_9"] {
                                    // Facturacion Electronica
                                    background-image: url("./../../../public/assets/aplicacion/fe.svg");
                                }
                                &[data-value="item_1"] {
                                    // Busint
                                    background-image: url("./../../../public/assets/aplicacion/busint.svg");
                                }
                                &[data-value="item_12"] {
                                    // Admin Posint
                                    background-image: url("./../../../public/assets/aplicacion/admin.svg");
                                }
                            }
                        }
                    }
                    .error-message {
                        position: absolute;
                        bottom: -25px;
                        left: 45%;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #EA3A3D;
                        display: none;
                    }
                }
                label {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 19px;
                    color: #2F3947;
                    width: 25%;
                    max-width: 167px;
                    @media screen and (max-width: 790px) {
                        width: 100%;
                        max-width: none;
                        margin-bottom: 20px;
                    }
                    span {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #809FB8;
                        display: block;
                    }
                }
                input[type="submit"] {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    color: #FFFFFF;
                    background: #2FB3D1;
                    border-radius: 12px;
                    border: 0;
                    padding: 13px 60px;
                    &:hover {
                        background: linear-gradient(180deg, #EDF4FD 47.37%, #FFFFFF 151.25%);
                        box-shadow: 0px 10px 20px rgba(37, 63, 100, 0.25);
                        color: #2F3947;
                    }
                }
                textarea {
                    border: 1px solid #D3DFF3;
                    border-radius: 12px;
                    width: 100%;
                    padding: 10px;
                    &:focus-visible {
                        border: 1px solid #62ACA7;
                        outline: #62ACA7;
                    }
                    &:valid {
                        border: 1px solid #62ACA7;
                        outline: #62ACA7;
                        box-shadow: 0px 0px 0px 3px rgba(98, 172, 167, 0.2);
                    }
                    &:invalid {
                        border: 1px solid #EA3A3D;
                    }
                }
                .file-trigger {
                    width: 120px !important;
                    height: 90px;
                    border: 0 !important;
                    border-radius: 12px;
                    text-align: center;
                    display: block !important;
                    vertical-align: middle;
                    line-height: 83px;
                    font-size: 50px;
                    font-weight: 100;
                    color: #2F3947;
                    cursor: pointer;
                    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2396B5CEFF' stroke-width='1' stroke-dasharray='11' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");
                    &:hover {
                        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%232FB3D1FF' stroke-width='1' stroke-dasharray='11' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");                    
                    }
                }
                > .error-message {
                    position: absolute;
                    bottom: -25px;
                    left: 20%;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #EA3A3D;
                    display: none;
                }
            }
            &.hidden {
                display: none;
            }
        }
        .image-layer {
            width: 100%;
            position: relative;
            display: none;
            &.active {
                display: block;
            }
            &__container {
                background: #FFFFFF;
                border-radius: 20px;
                position: relative;
                form {
                    padding: 20px;
                    > div {
                        padding: 10px 25px;
                        margin-bottom: 0;
                        > div {
                            @media screen and (max-width: 790px) {
                                flex-direction: row;
                                margin-bottom: 0;
                                align-items: center;
                            }
                            &:last-child {
                                justify-content: flex-end;
                            }
                        }
                        &:last-child {
                            margin-top: 0;
                            margin-bottom: 0;
                            justify-content: space-between;
                            align-items: center;
                            @media screen and (max-width: 960px) {
                                flex-direction: row;
                            }
                            @media screen and (max-width: 590px) {
                                flex-direction: column;
                            }
                        }
                        h2 {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 22px;
                            color: #2F3947;
                        }
                        .close {
                            background-image: url("./../../../public/assets/close.svg");
                            background-repeat: no-repeat;
                            background-size: 12px;
                            background-position: 95% center;
                            width: 17px !important;
                            height: 15px;
                            position: absolute;
                            right: 0;
                            cursor: pointer;
                        }
                        textarea {
                            width: 100%;
                            padding: 10px;
                            border: 1px solid #CEE1EA;
                            border-radius: 10px;
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 19px;
                            color: #8FB8CE;
                            &:focus-visible {
                                border: 1px solid #62ACA7;
                                outline: #62ACA7;
                            }
                            &:valid {
                                border: 1px solid #62ACA7;
                                outline: #62ACA7;
                                box-shadow: 0px 0px 0px 3px rgba(98, 172, 167, 0.2);
                            }
                        }
                        button[type="submit"] {
                            background-color: #62ACA7;
                            border-radius: 12px;
                            border: none;
                            padding: 12px 40px;
                            width: 130px;
                            height: 40px;
                            cursor: pointer;
                            align-self: center;
                            transition: all .9s ease;
                            background-position: center 50px;
                            span {
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 15px;
                                text-align: center;
                                color: #FFFFFF;
                                opacity: 1;
                                transition: all .6s ease;
                                display: block;
                            }
                            &:hover {
                                background-image: url("./../../../public/assets/clip.svg");
                                background-repeat: no-repeat;
                                background-size: 15px;
                                background-position:center center;
                                color: #62ACA7;
                                span {
                                    transform: translateY(-100%);
                                    opacity: 0;
                                    
                                }
                            }
                        }
                        .input-file {
                            margin-right: 15px;
                            label {
                                width: 65px;
                                height: 55px;
                                border: 0;
                                border-radius: 12px;
                                text-align: center;
                                display: block;
                                vertical-align: middle;
                                line-height: 52px;
                                font-size: 40px;
                                font-weight: 100;
                                color: #2F3947;
                                cursor: pointer;
                                position: relative;
                                margin: 0 15px 0 auto;
                                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2396B5CEFF' stroke-width='1' stroke-dasharray='11' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");
                                &:hover {
                                    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%232FB3D1FF' stroke-width='1' stroke-dasharray='11' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");                    
                                }
                            }
                            input[type="file"] {
                                display: none;
                            }
                            &.preview {
                                width: 65px !important;
                                height: 55px;
                                border: 1px solid #CEE1EA;
                                border-radius: 10px;
                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    > .image {
                        position: relative;
                        &:hover {
                            > img {
                                -webkit-filter: brightness(60%);
                                -webkit-transition: all .7s ease;
                                -moz-transition:  all .7s ease;
                                -o-transition:  all .7s ease;
                                -ms-transition:  all .7s ease;
                                transition:  all .7s ease;
                            }
                            > div {
                                
                                opacity: 1;
                                span {
                                    transform: translateX(0);
                                }
                                button {
                                    transform: translateX(0);
                                }
                            }
                        }
                        > div {
                            position: absolute;
                            bottom: 35px;
                            right: 60px;
                            transition: all 1s ease;
                            opacity: 0;
                            display: flex !important;
                            span {
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 19px;
                                color: #FFFFFF;
                                margin-right: 20px;
                                transform: translateX(-100%);
                                transition: transform .7s ease;
                            }
                            button {
                                cursor: pointer;
                                border: none;
                                background-color: transparent;
                                transform: translateX(100%);
                                transition: transform .7s ease;
                            }
                        }
                        img {
                            width: 100%;
                            max-height: 350px;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}