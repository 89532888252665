.info-summary {
    width: 55%;
    height: fit-content;
    @media screen and (max-width: 1120px) {
        width: 100%;
    }
    .back {
        color: #2F3947;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 40px;
        padding: 0;
        cursor: pointer;
        // &::before {
        //     content: "";
        //     width: 60px;
        //     height: 5px;
        //     display: inline-block;
        //     // background-image: url("./../../../public/assets/arrow-back.svg");
        //     background-repeat: no-repeat;
        //     background-position: center;
        //     margin-right: 15px;
        // }
        @media screen and (max-width: 790px) {
            font-size: 11px;
        }
        span {
            display: inline-block;
            cursor: pointer;
            border-bottom: 2px solid #2F3947;
            // padding: 0 5px;
        }
    }
    &__container {
        padding: 20px 40px;
        @media screen and (max-width: 1250px) {
            padding: 20px;
            display: flex;
            flex-direction: column;
        }
    }
    &__body {
        display: block;
        width: 100%;
        position: relative;
        padding: 0 40px 40px 40px;
        @media screen and (max-width: 1250px) {
            padding: 0 20px 20px 20px;
            order: 2;
        }
        @media screen and (max-width: 790px) {
            padding: 20px 0;
        }
        h3 {
            margin: 0 0 20px 0;
            color: #2F3947;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            @media screen and (max-width: 790px) {
                font-size: 12px;
            }
            span {
                color: #809FB8;
                @media screen and (max-width: 790px) {
                    display: block;
                }
                &.state {
                    color: #F8A529;
                    font-weight: 700;
                    border-radius: 20px;
                    border: 1px solid #F9B959;
                    background: #FFF3E0;
                    padding: 5px 15px;
                    margin-left: 15px;
                    width: fit-content;
                    @media screen and (max-width: 790px) {
                        margin-left: 0;
                        margin-top: 10px;
                    }
                    &--confirmed {
                        border: 1px solid #1AD598;
                        background: #E4F4EF;
                        font-weight: 700;
                        border-radius: 20px;
                        background: #E4F4EF;
                        padding: 5px 15px;
                        margin-left: 15px;
                        color: #25C893;
                        width: fit-content;
                        @media screen and (max-width: 790px) {
                            margin-left: 0;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
        h2 {
            margin: 0 0 20px 0;
            color: #2F3947;
            font-family: "Montserrat";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            @media screen and (max-width: 790px) {
                font-size: 16px;
            }
        }
        > div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            position: relative;
            padding: 20px 0 0 0;
            > div {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                position: relative;
                width: 100%;
                margin: 0 0 20px 0;
                @media screen and (max-width: 790px) {
                    flex-direction: column;
                }
                > div {
                    width: 65%;
                    @media screen and (max-width: 790px) {
                        width: 100%;
                    }
                    &:first-child {
                        width: calc(35% - 20px);
                        margin-right: 20px;
                        @media screen and (max-width: 790px) {
                            width: 100%;
                            margin-right: 0;
                            margin-bottom: 10px;
                        }
                        > span {
                            color: #687484;
                            font-family: "Open Sans";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            display: block;
                        }
                    }
                    &.img {
                        display: flex;
                        flex-direction: column;
                        > div {
                            display: flex;
                            flex-direction: row;
                            margin: 0 0 20px 0;
                            justify-content: flex-start;
                            align-items: center;
                            position: relative;
                            img {
                                width: 74px;
                                height: 68px;
                                object-fit: cover;
                            }
                            span {
                                color: #2F3947;
                                font-family: "Montserrat";
                                font-size: 10px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 12px;
                                border-radius: 20px;
                                background: #FFF;
                                position: absolute;
                                left: 45px;
                                top: 45px;
                                padding: 1px 4px;
                            }
                            p {
                                margin: 10px;
                                color: #2F3947;
                                font-family: "Open Sans";
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 22px;
                            }
                        }
                    }
                    label {
                        color: #2F3947;
                        font-family: "Open Sans";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        @media screen and (max-width: 790px) {
                            font-size: 12px;
                        }
                    }
                    p {
                        color: #2F3947;
                        font-family: "Open Sans";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                        margin: 0;
                        @media screen and (max-width: 790px) {
                            font-size: 12px;
                        }
                    }
                }

            }
        }
        > .show-content {
            display: none;
            position: relative;
            max-height: none !important;
            overflow: visible;
            color: #2F3947;
            font-family: "Montserrat";
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            border-radius: 10px;
            border: 1px solid #99B2C6;
            padding: 12px 40px;
            margin-right: 20px;
            background-color: transparent;
            width: fit-content;
            cursor: pointer;
            margin-top: 30px;
            &:hover {
                background-color: white;
            }
            @media screen and (max-width: 1120px) {
                display: block;

            }
        }
    }
    &__btn {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0 40px 40px 40px;
        @media screen and (max-width: 1250px) {
            padding: 20px;
            order: 1;
        }
        @media screen and (max-width: 790px) {
            padding: 20px 0;
        }
        button {
            cursor: pointer;
            &.cancel {
                color: #2F3947;
                font-family: "Montserrat";
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                border-radius: 10px;
                border: 1px solid #99B2C6;
                padding: 12px 40px;
                margin-right: 20px;
                background-color: transparent;
                &:hover {
                    background-color: white;
                }
            }
        }
        p {
            color: #2F3947;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            margin: 0 20px 0 0;
            @media screen and (max-width: 790px) {
                font-size: 12px;
            }
        }
        span {
            color: #2F3947;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            margin: 0 5px 0 0;
            @media screen and (max-width: 790px) {
                font-size: 12px;
            }
        }
    }
    &.leftSide {
        width: 50%;
        @media screen and (max-width: 1120px) {
            width: 100%;
            margin-bottom: 50px;
        }
        .info-summary__container {
            padding: 0 40px 20px 0;
            .info-summary__body {
                padding: 0 40px 40px 0;
                @media screen and (max-width: 1120px) {
                    padding: 10px 40px 0 0;
                }
                h3 {
                    span {
                        @media screen and (max-width: 790px) {
                            display: inline-block;
                        }
                        &.state {
                            @media screen and (max-width: 790px) {
                                margin-left: 50px;
                            }
                        }
                    }
                }
                > div {
                    @media screen and (max-width: 1120px) {
                        max-height: 130px;
                        overflow: hidden;
                    }
                    @media screen and (max-width: 790px) {
                        max-height: 220px;
                    }
                }
                &.active {
                    > div {
                        max-height: none;
                    }
                }
            }
            .info-summary__btn {
                padding: 0 0 40px 0;
            }
        }
    }
}