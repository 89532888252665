.header {
    height: 80px;
    background-color: white;
    border-radius: 20px;
    position: relative;
    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        height: 100%;
        padding: 0 25px;
        div:not([class]) {
            height: 100%;
            width: 50%;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            @media screen and (max-width: 690px) {
                width: 80%;
            }
        }
    }
}