.chat {
    border-radius: 20px;
    background-color: #FFF;
    width: 45%;
    height: fit-content;
    margin: 0 0 0 auto;
    @media screen and (max-width: 1120px) {
        width: 100%;
    }
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
    }
    &__header {
        width: 100%;
        position: relative;
        padding: 10px 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #D5EBF8;
        div {
            display: flex;
            align-items: center;
            flex-direction: row;
            div {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: flex-start;
                &:first-child {
                    margin-right: 20px;
                    flex-direction: row;
                }
                h4 {
                    color: #2F3947;
                    font-family: "Open Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin: 0;
                }
                h6 {
                    color: #809FB8;
                    font-family: "Open Sans";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0;
                }
                img {
                    width: 38px;
                    height: 41px;
                }
            }
            .phone {
                width: 58px;
                height: 48px;
                background-color: #F1F4F9;
                border: none;
                border-radius: 8px;
                cursor: pointer;
                background-image: url("./../../../public/assets/reload.png");
                background-repeat: no-repeat;
                background-position: center;
                &:hover {
                    // background-image: url("./../../../public/assets/phone-hover.png");
                    background-color: #2FB3D1;
                }
            }
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 30px;
        background-color: #F1F4F9;
        min-height: 450px;
        border-bottom: 1px solid #D5EBF8;
        width: 100%;
        max-height: 460px;
        overflow-y: auto;
        .self {
            position: relative;
            width: 90%;
            margin: 0 auto 25px 0;
            p {
                margin: 0 0 5px 0;
                color: #2F3947;
                font-family: "Open Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                span {
                    color: #2F3947;
                    font-family: "Open Sans";
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
            div {
                color: #2F3947;
                font-family: "Open Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 12px;
                background-color: #FFF;
                padding: 10px;
                min-width: 100%;
            }
        }
        .user {
            position: relative;
            width: 70%;
            margin: 0 0 0 auto;
            p {
                margin: 0 0 5px 0;
                color: #2F3947;
                font-family: "Open Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                span {
                    color: #2F3947;
                    font-family: "Open Sans";
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
            div {
                min-width: 100%;
                color: #2F3947;
                font-family: "Open Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 12px;
                background-color: #83F6D1;
                padding: 10px;
            }
        }
    }
    &__footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 20px 20px 20px;
        width: 100%;
        flex-wrap: wrap;
        input[type="text"] {
            display: block;
            width: 100%;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 10px;
            border: none;
            &::placeholder {
                color: #809FB8;
            }
            &:focus-visible {
                border: none;
                outline: none;
            }
        }
        > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            margin: 20px 0 0 0;
            > div {
                .icons {
                    border: none;
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    background-image: url("./../../../public/assets/icons-thumb.png");
                    background-repeat: no-repeat;
                    background-size: 15px;
                    background-position:center center;
                    cursor: pointer;
                    &:hover {
                        background-image: url("./../../../public/assets/icons-thumb-hover.png");
                    }
                }
                .file {
                    border: none;
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    background-image: url("./../../../public/assets/file-icon.png");
                    background-repeat: no-repeat;
                    background-size: 15px;
                    background-position:center center;
                    cursor: pointer;
                    &:hover {
                        background-image: url("./../../../public/assets/file-hover.png");
                    }
                }
                button[type="submit"] {
                    background-color: #62ACA7;
                    border-radius: 12px;
                    border: none;
                    padding: 12px 40px;

                    cursor: pointer;
                    align-self: center;
                    transition: all .9s ease;
                    background-position: center 50px;
                    span {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;
                        text-align: center;
                        color: #FFFFFF;
                        opacity: 1;
                        transition: all .6s ease;
                        display: block;
                    }
                    &:hover {
                        background-image: url("./../../../public/assets/send-icon.png");
                        background-repeat: no-repeat;
                        background-size: 15px;
                        background-position:center center;
                        color: #62ACA7;
                        span {
                            transform: translateY(-100%);
                            opacity: 0;
                            
                        }
                    }
                }
            }
        }
    }
}