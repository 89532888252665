@import-normalize;

* {
  box-sizing: border-box;
}

body {
  padding: 25px;
  background: #EAECF8;
  height: 100%;
  width: 100%;
  margin: 0;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  @media screen and (max-width: 690px) {
    padding: 15px;
}
}



