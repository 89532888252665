.table-component {
    display: block;
    width: 100%;
    height: fit-content;
    position: relative;
    &__container {
        display: block;
        width: 100%;
        height: fit-content;
        position: relative;
        .table-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
            > div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .back {
                    color: #2F3947;
                    font-family: "Open Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;
                    padding: 0;
                    &::before {
                        content: "";
                        width: 60px;
                        height: 5px;
                        display: inline-block;
                        background-image: url("./../../../public/assets/arrow-back.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        margin-right: 15px;
                    }
                    @media screen and (max-width: 690px) {
                        font-size: 11px;
                    }
                }
                > p {
                    color: #2F3947;
                    font-family: "Open Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0;
                    @media screen and (max-width: 690px) {
                        font-size: 12px;
                    }
                }
                > button {
                    color: #FFF;
                    font-family: "Montserrat";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    border-radius: 12px;
                    background-color: #2FB3D1;
                    border: none;
                    padding: 12px 40px;
                    margin-left: 10px;
                    cursor: pointer;
                    &:hover {
                        color: #2F3947;
                        background-color: #EDF4FD;
                        box-shadow: 0px 10px 20px 0px rgba(37, 63, 100, 0.25);
                    }
                    @media screen and (max-width: 690px) {
                        font-size: 10px;
                    }
                }
            }
        }
        .table-body {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            border-radius: 20px;
            background-color: white;
            flex-wrap: wrap;
            > div:not([class]) {
                position: relative;
                width: 50%;
                padding: 40px 40px 20px 40px;
                h4 {
                    color: #2F3947;
                    font-family: "Montserrat";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin: 0 0 8px 0;
                    @media screen and (max-width: 690px) {
                        font-size: 16px;
                    }
                }
                h6 {
                    color: #809FB8;
                    font-family: "Open Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0;
                    @media screen and (max-width: 690px) {
                        font-size: 12px;
                    }
                }
            }
            > .pagination {
                position: relative;
                width: 50%;
                padding: 40px 40px 20px 40px;
                position: relative;
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                > p {
                    color: #2F3947;
                    font-family: "Open Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    @media screen and (max-width: 690px) {
                        font-size: 12px;
                    }
                }
                > button {
                    background-color: white;
                    padding: 15px 20px;
                    border: 1px solid #D9E1E7;
                    cursor: pointer;
                    &.left {
                        border-radius: 10px 0 0 10px;
                        // background-image: url("./../../../public/assets/back-arrow-icon.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        margin: 0 0 0 15px;
                    }
                    &.right {
                        border-radius: 10px;
                        border-width: 1px 1px 1px 0;
                        // background-image: url("./../../../public/assets/next-arrow-icon.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                    }

                }
            }
            > .table {
                position: relative;
                width: 100%;
                padding: 20px;
                table {
                    table-layout: fixed;
                    width: 100%;
                    border-color: transparent;
                    @media screen and (max-width: 690px) {
                        table-layout: auto;
                    }
                    thead {
                        tr {
                            th {
                                color: #809FB8;
                                font-family: "Open Sans";
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                text-align: left;
                                padding: 0 10px 10px 10px;
                                position: relative;
                                &:nth-child(1) {
                                    width: 3%;
                                    @media screen and (max-width: 1200px) {
                                        width: 10%;
                                    }
                                    @media screen and (max-width: 960px) {
                                        width: 10%;
                                    }
                                    @media screen and (max-width: 690px) {
                                        width: auto;
                                    }
                                }
                                &:nth-child(2) {
                                    width: 6%;
                                    @media screen and (max-width: 1200px) {
                                        width: 10%;
                                    }
                                    @media screen and (max-width: 960px) {
                                        width: 20%;
                                    }
                                    @media screen and (max-width: 690px) {
                                        width: auto;
                                    }
                                }
                                &:nth-child(3) {
                                    width: 7%;
                                    @media screen and (max-width: 1200px) {
                                        width: 10%;
                                    }
                                    @media screen and (max-width: 960px) {
                                        width: 20%;
                                    }
                                    @media screen and (max-width: 690px) {
                                        width: auto;
                                    }
                                }
                                &:nth-child(4) {
                                    width: 25%;
                                    @media screen and (max-width: 1200px) {
                                        width: 20%;
                                    }
                                    @media screen and (max-width: 960px) {
                                        display: none;
                                    }
                                }
                                &:nth-child(5) {
                                    width: 32%;
                                    @media screen and (max-width: 1200px) {
                                        width: 20%;
                                    }
                                    @media screen and (max-width: 960px) {
                                        display: none;
                                    }
                                }
                                &:nth-child(6) {
                                    width: 10%;
                                    @media screen and (max-width: 1200px) {
                                        width: 20%;
                                    }
                                    @media screen and (max-width: 690px) {
                                        width: auto;
                                    }
                                }
                                &:nth-child(7) {
                                    width: 10%;
                                    @media screen and (max-width: 960px) {
                                        width: 20%;
                                    }
                                    @media screen and (max-width: 690px) {
                                        width: auto;
                                    }
                                }
                                &:nth-child(8) {
                                    width: 7%;
                                    @media screen and (max-width: 1200px) {
                                        width: 10%;
                                    }
                                    @media screen and (max-width: 960px) {
                                        width: 20%;
                                    }
                                    @media screen and (max-width: 690px) {
                                        width: auto;
                                    }
                                }
                            }
                        }
                    }
                    tbody {
                        tr {
                            position: relative;
                            height: 55px;
                            &:nth-child(odd){
                                &::after {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    width: 100%;
                                    border: 1px solid #D3DFF3;
                                    border-radius: 10px;
                                    pointer-events: none;
                                    right: -1px;
                                }
                            }
                            td {
                                text-align: left;
                                padding: 0 10px;
                                position: relative;
                                &:nth-child(1) {
                                    width: 3%;
                                    @media screen and (max-width: 1200px) {
                                        width: 10%;
                                    }
                                    @media screen and (max-width: 960px) {
                                        width: 10%;
                                    }
                                    @media screen and (max-width: 690px) {
                                        width: auto;
                                    }
                                }
                                &:nth-child(2) {
                                    width: 6%;
                                    @media screen and (max-width: 1200px) {
                                        width: 10%;
                                    }
                                    @media screen and (max-width: 960px) {
                                        width: 20%;
                                    }
                                    @media screen and (max-width: 690px) {
                                        width: auto;
                                    }
                                }
                                &:nth-child(3) {
                                    width: 7%;
                                    @media screen and (max-width: 1200px) {
                                        width: 10%;
                                    }
                                    @media screen and (max-width: 960px) {
                                        width: 20%;
                                    }
                                    @media screen and (max-width: 690px) {
                                        width: auto;
                                    }
                                }
                                &:nth-child(4) {
                                    width: 25%;
                                    @media screen and (max-width: 1200px) {
                                        width: 20%;
                                    }
                                    @media screen and (max-width: 960px) {
                                        display: none;
                                    }
                                }
                                &:nth-child(5) {
                                    width: 32%;
                                    @media screen and (max-width: 1200px) {
                                        width: 20%;
                                    }
                                    @media screen and (max-width: 960px) {
                                        display: none;
                                    }
                                }
                                &:nth-child(6) {
                                    width: 10%;
                                    @media screen and (max-width: 1200px) {
                                        width: 20%;
                                    }
                                    @media screen and (max-width: 690px) {
                                        width: auto;
                                    }
                                }
                                &:nth-child(7) {
                                    width: 10%;
                                    @media screen and (max-width: 960px) {
                                        width: 20%;
                                    }
                                    @media screen and (max-width: 690px) {
                                        width: auto;
                                    }
                                }
                                &:nth-child(8) {
                                    width: 7%;
                                    text-align: center;
                                    @media screen and (max-width: 1200px) {
                                        width: 10%;
                                    }
                                    @media screen and (max-width: 960px) {
                                        width: 20%;
                                    }
                                    @media screen and (max-width: 690px) {
                                        width: auto;
                                    }
                                }
                                &.state {
                                    > p {
                                        color: #F8A529;
                                        font-weight: 700;
                                        border-radius: 20px;
                                        border: 1px solid #F9B959;
                                        background: #FFF3E0;
                                        padding: 5px 15px;
                                        margin-left: 15px;
                                        width: 103px;
                                        margin: 0;
                                        text-align: center;
                                        font-size: 12px;
                                        &.confirmed {
                                            border: 1px solid #1AD598;
                                            background: #E4F4EF;
                                            font-weight: 700;
                                            border-radius: 20px;
                                            background: #E4F4EF;
                                            padding: 5px 15px;
                                            margin-left: 15px;
                                            color: #25C893;
                                            width: fit-content;
                                            margin: 0;
                                            width: 103px;
                                        }
                                    }
                                }
                                &.view {
                                    > button {
                                        background-image: url("./../../../public/assets/eye.svg");
                                        background-position: center center;
                                        background-repeat: no-repeat;
                                        background-size: 50%;
                                        background-color: white;
                                        border: 1px solid #D3DFF3;
                                        border-radius: 20px;
                                        width: 43px;
                                        height: 28px;
                                        cursor: pointer;
                                        position: relative;
                                        &:hover {
                                            box-shadow: 0px 3px 4px 0px rgba(47, 57, 71, 0.15);
                                            border: 1px solid #fff;
                                        }
                                        .notification {
                                            width: 15px;
                                            height: 15px;
                                            border-radius: 50%;
                                            background-color: #EA3A3D;
                                            position: absolute;
                                            top: -5px;
                                            right: -5px;
                                            display: block;
                                        }
                                    }
                                }
                                &.avatar {
                                    padding: 0 0 0 10px;
                                }
                                > p {
                                    width: 100%;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    color: #2F3947;
                                    font-family: "Open Sans";
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    padding: 10px 0;
                                    margin: 0;
                                    &.bold {
                                        font-weight: 600;
                                        white-space: nowrap;
                                        text-overflow: none;
                                        overflow:  visible;
                                    }
                                    
                                }
                                > img {
                                    width: 100%;
                                    max-width: 25px;
                                }
                            }
                        }
                    }
                    tfoot {
                        tr {
                            td {
                                > label.check {
                                    display: block;
                                    position: relative;
                                    padding-left: 35px;
                                    margin-top: 20px;
                                    cursor: pointer;
                                    -webkit-user-select: none;
                                    -moz-user-select: none;
                                    -ms-user-select: none;
                                    user-select: none;
                                    color: #2F3947;
                                    font-family: "Open Sans";
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    input[type="checkbox"] {
                                        position: absolute;
                                        opacity: 0;
                                        cursor: pointer;
                                        height: 0;
                                        width: 0;
                                        &:checked {
                                            ~ span {
                                                background-color: #62ACA7;
                                                border: 2px solid #62ACA7;
                                                &::after {
                                                    display: block;
                                                }
                                            }
                                            &:hover {
                                                ~ span {
                                                    background-color: #62ACA7;
                                                    border: 2px solid #62ACA7;
                                                    &::after {
                                                        display: block;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    span {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        height: 25px;
                                        width: 25px;
                                        background-color: #fff;
                                        border: 2px solid #D3DFF3;
                                        border-radius: 6px;
                                        &:after {
                                            content: "";
                                            position: absolute;
                                            display: none;
                                            left: 7px;
                                            top: 3px;
                                            width: 5px;
                                            height: 10px;
                                            border: solid white;
                                            border-width: 0 3px 3px 0;
                                            -webkit-transform: rotate(45deg);
                                            -ms-transform: rotate(45deg);
                                            transform: rotate(45deg);
                                        }
                                    }
                                    &:hover {
                                        input[type="checkbox"] ~ span {
                                            background-color: transparent;
                                            border: 2px solid #0090FF;
                                        }
                                    } 
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }
    &.skeleton {
        .table-component__container {
            .table-top {
                > div {
                    &:last-child {
                        > button {
                            color: transparent;
                            // animation: skeleton-loading 2s linear infinite alternate;

                            background: #eee;
                            background: linear-gradient(110deg, #DDDFEC 4%, #EBEDF4 18%, #DDDFEC 33%);
                            border-radius: 5px;
                            background-size: 200% 100%;
                            animation: 2s shine linear infinite;
                        }
                    }
                }
            }
            .table-body {
                > .pagination {
                    > p{
                        color: transparent;
                        background: #eee;
                        background: linear-gradient(110deg, #EBEDF4 4%, #DDDFEC 18%, #EBEDF4 33%);
                        border-radius: 10px;
                        background-size: 200% 100%;
                        animation: 2s shine linear infinite;
                        padding: 10px 0;
                        margin: 0;
                    }
                    > button {
                        color: transparent;
                        background: #eee;
                        background: linear-gradient(110deg, #EBEDF4 4%, #DDDFEC 18%, #EBEDF4 33%);
                        border-radius: 5px;
                        background-size: 200% 100%;
                        animation: 2s shine linear infinite;
                        border: none;
                        height: 38px;
                    }
                }
                > .table {
                    table {
                        tbody {
                            tr {
                                td {
                                    > p {
                                        color: transparent;
                                        background: #eee;
                                        background: linear-gradient(110deg, #EBEDF4 4%, #DDDFEC 18%, #EBEDF4 33%);
                                        border-radius: 10px;
                                        background-size: 200% 100%;
                                        animation: 2s shine linear infinite;
                                        border: none;
                                        padding: 10px 0;
                                    }
                                    > button {
                                        height: 38px;
                                        color: transparent;
                                        background: #eee;
                                        background: linear-gradient(110deg, #EBEDF4 4%, #DDDFEC 18%, #EBEDF4 33%);
                                        border-radius: 10px;
                                        background-size: 200% 100%;
                                        animation: 2s shine linear infinite;
                                        border: none;
                                        padding: 10px 0;
                                        .notification {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.transparent {
        .table-component__container {
            .table-body {
                tr {
                    background-color: #fff;
                }
            }
        }
    }
}

@keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

@keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }