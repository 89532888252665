.logo {
    height: 100%;
    width: 50%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 690px) {
        width: auto;
    }
    figure {
        margin: 0;
        img {
            width: 100%;
        }
    }
    .title {
        margin-left: 25px;
        @media screen and (max-width: 690px) {
            display: none;
        }
        h3 {
            font-size: 18px;
            margin: 0;
            font-weight: 700;
            line-height: 22px;
            color: #2F3947;
            @media screen and (max-width: 830px) {
                font-size: 16px;
                line-height: 20px;
            }
        }
        H4 {
            font-size: 14px;
            margin: 0;
            font-weight: 400;
            line-height: 20px;
            color: #809FB8;
            @media screen and (max-width: 830px) {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }
}