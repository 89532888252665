.fileViewer {
    width: 100vw;
    /* 100% del ancho de la ventana */
    height: 100vh;
    /* 100% del alto de la ventana */
    overflow: auto;
    /* Agrega barras de desplazamiento si el contenido es más grande que el contenedor */
}


.wrapper {
    width: 100%;
    /* 100% del ancho del elemento padre */
    height: 100%;
    /* 100% del alto del elemento padre */
    /* Otros estilos que necesites */
}