.end-process {
    border-radius: 20px;
    background-color: #FFF;
    box-shadow: 0px 10px 20px 0px rgba(37, 63, 100, 0.25);
    width: 45%;
    height: fit-content;
    @media screen and (max-width: 1120px) {
        width: 100%;
    }
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px;
        @media screen and (max-width: 1250px) {
            padding: 20px;
        }
        @media screen and (max-width: 1120px) {
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 45% 1fr;
            display: grid;
            max-height: 330px;
            overflow: hidden;
        }
        @media screen and (max-width: 690px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        > div:not([class]) {
            h3 {
                color: #2F3947;
                text-align: center;
                font-family: "Open Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin: 0 0 20px 0;
                @media screen and (max-width: 1120px) {
                    margin: 0 0 20px 20px;
                    text-align: left;           
                }
                @media screen and (max-width: 690px) {
                    text-align: center;
                    margin: 0 0 20px 0; 
                }
            }
            h5 {
                color: #809FB8;
                text-align: center;
                font-family: "Open Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0 0 20px 0;
                @media screen and (max-width: 1120px) {
                    margin: 0 0 20px 20px;  
                    text-align: left;         
                }
                @media screen and (max-width: 690px) {
                    text-align: center;
                    margin: 0 0 20px 0; 
                }
            }
            @media screen and (max-width: 1120px) {
                order: 2;
                width: 100%;
                grid-row-start: 1;
                grid-column-start: 2;
                grid-row-end: 2;
                grid-column-end: 2;     
                margin: 20px 0 0 40px;     
                width: fit-content;  
            }
            @media screen and (max-width: 690px) {
                width: 100%;
                align-items: center;
                margin: 0; 
            }
        }
        img {
            width: 100%;
            max-width: 376px;
            @media screen and (max-width: 1120px) {
                order: 1;
                grid-row-start: 1;
                grid-column-start: 1;
                grid-row-end: 3;
                grid-column-end: 2;
                margin: 0 auto;
                width: 100%;
                max-width: 300px;
                object-position: 0 30px;
                object-fit: cover;
            }
            @media screen and (max-width: 690px) {
                display: none;
            }
        }
        .btn {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin: 20px 0 0 0;
            @media screen and (max-width: 1120px) {
                order: 2;
                grid-row-start: 2;
                grid-column-start: 2;
                grid-row-end: 2;
                grid-column-end: 2;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin: 0 0 0 40px;
                width: fit-content; 
            }
            @media screen and (max-width: 690px) {
                width: 100%;
                align-items: center;
                margin: 20px 0 0 0;
            }
            button {
                border-radius: 12px;
                background: #2FB3D1;
                color: #FFF;
                font-family: "Montserrat";
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                border: none;
                padding: 12px 25px; 
                cursor: pointer;
                &.back {
                    &::before {
                        content: "";
                        width: 60px;
                        height: 5px;
                        display: inline-block;
                        background-image: url("./../../../public/assets/arrow-back-white.svg");
                        background-repeat: no-repeat;
                        background-position: center center;
                        margin-right: 15px;
                    }
                    &:hover {
                        &::before {
                            background-image: url("./../../../public/assets/arrow-back.svg");
                        }
                    }
                    @media screen and (max-width: 1120px) {
                        margin: 0 0 40px 0;
                    }
                    @media screen and (max-width: 690px) {
                        margin: 0 0 20px 0;
                    }
                }
                &:hover {
                    color: #2F3947;
                    background: linear-gradient(180deg, #EDF4FD 51.40%, #FFF 100%);
                    box-shadow: 0px 10px 20px 0px rgba(37, 63, 100, 0.25);
                }
            }
        }
    }
}