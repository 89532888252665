.profile {
    position: relative;
    display: block;
    width: 100px;
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        div {
            height: auto !important;
            width: 100% !important;
            position: relative !important;
            figure {
                margin: 0 10px 0 0;
                img {
                    width: 46px;
                }
            }
            .trigger {
                background-image: url("./../../../public/assets/arrow-down.svg");
                display: block;
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: bottom;
                width: 20px !important;
                height: 15px !important;
                position: relative;
                cursor: pointer;
            }
        }
        section {
            display: block;
            position: absolute;
            background-color: white;
            box-shadow: 0px 10px 20px rgba(37, 63, 100, 0.25);
            border-radius: 12px;
            top: -55px;
            right: -15px;
            opacity: 0;
            transition: all .5s ease;
            z-index: -1;
            &.active {
                opacity: 1;
                transition: all .9s ease;
                top: 55px;
                z-index: 1;
            }
            ul {
                padding: 0;
                margin: 0;
                list-style: none;
                li {
                    border-bottom: 1px solid #F1F4F9;
                    padding: 15px 15px;
                    text-align: center;
                    &:last-child {
                        padding: 10px 15px 10px 2px;
                        border: none;
                    }
                    h4 {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 19px;
                        color: #243746;
                        opacity: 0.8;
                        text-align: center;
                        margin: 0;
                    }
                    p {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                        color: #809FB8;
                        opacity: 0.8;
                        margin: 0;
                    }
                    a {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #2F3947;
                        opacity: 0.8;
                        text-decoration: none;
                        text-align: center;
                        position: relative;
                        &::after {
                            content: "";
                            background-image: url("./../../../public/assets/logout-icon.svg");
                            background-position: center;
                            background-size: auto;
                            background-repeat: no-repeat;
                            position: absolute;
                            top: 3px;
                            width: 12px;
                            height: 12px;
                            right: -20px;
                        }
                        &:hover {
                            &::after {
                                background-image: url("./../../../public/assets/logout-icon-hover.svg");
                            }
                        }
                    }
                }
            }
        }
    }
}