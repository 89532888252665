.footer {
    display: block;
    position: absolute;
    width: calc(100% - 40px);
    bottom: -5%;
    left: 20px;
    &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 670px) {
            flex-direction: column;
            justify-content: center;
        }
        div {
            width: 50%;
            @media screen and (max-width: 670px) {
                width: 100%;
                text-align: center;
            }
            a {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                text-decoration-line: underline;
                color: #2F3947;
                margin-right: 25px;
                @media screen and (max-width: 760px) {
                    font-size: 12px;
                }
            }
            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #2F3947;
                text-align: right;
                @media screen and (max-width: 760px) {
                    font-size: 12px;
                }
                @media screen and (max-width: 670px) {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}