.search {
    position: relative;
    height: 48px;
    background-color: #F1F4F9;
    border-radius: 12px;
    display: flex;
    width: 315px;
    justify-content: flex-start;
    padding: 0 35px 0 0;
    transition: background-color .2s;
    @media screen and (max-width: 830px) {
        width: 230px;
    }
    &.collapsed {
        width: 20px;
        padding: 0;
        background-color: transparent;
        cursor: pointer;
        &::before {
            margin: 0;
        }
        input[type="text"] {
            display: none;    
        }
    }
    &:focus-within {
        box-shadow: 0 4px 15px -4px rgba(0, 0, 0, 0.3);
        background-color: white;
        &::before {
            background-image: url("./../../../public/assets/search-icon-hover.svg");
        }
    }
    &::before {
        content: "";
        background-image: url("./../../../public/assets/search-icon.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        width: 15px;
        margin: 0 15px 0 18px;
    }
    input[type="text"] {
        background-color: transparent;
        border: none;
        font-size: 14px;
        font-weight: 400;
        outline: none;
        @media screen and (max-width: 830px) {
            font-size: 12px;
        }
        &::placeholder {
            color: #809FB8;
            font-size: 14px;
            font-weight: 400;
            @media screen and (max-width: 830px) {
                font-size: 12px;
            }
        }

    }
}